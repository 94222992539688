.container {
    height    : 60px;
    background: transparent;
    width     : 100;
}

.navbar {
    background   : var(--tertiary);
    border-bottom: 2px var(--primary) solid;
    height       : 60px;
    display      : flex;
    position     : fixed;
    top          : 0;
    left         : 0;
    right        : 0;
    z-index      : 100;
}

.btnContainer {
    outline            : none;
    border             : none;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    cursor             : pointer;
    position           : absolute;
    width              : 60px;
    height             : 60px;
    border-radius      : 50%;
    color              : var(--primary);
    background         : transparent;
    right              : 10px;

}

.sidebar {
    margin-top          : 60px;
    height              : 100vh;
    width               : 100%;
    background          : var(--tertiary);
    z-index             : 100;
    list-style          : none;
    justify-content     : left;
    text-align          : justify;
    margin-block-end    : 0;
    margin-inline-start : 0px;
    margin-inline-end   : 0px;
    padding-inline-start: 0;
}

.sideWrap {
    color     : var(--primary);
    list-style: none;
    margin    : 10px 30px 0 30px;
    cursor    : pointer;
    padding   : 20px;
}


.sideWrap:hover {
    color: var(--primary);
}

.sideWrap::after {
    content   : '';
    display   : block;
    width     : 0;
    transition: width .3s ease-in-out;
}


.sideWrap:hover::after {
    margin       : auto;
    width        : 80%;
    border-bottom: 2px solid var(--primary);
}

.sideWrapActive {
    position     : relative;
    background   : var(--secondary);
    list-style   : none;
    padding      : 10px;
    margin       : 10px 30px 0 30px;
    cursor       : default;
    color        : var(--tertiary);
    border-radius: 20px
}

.logo {
    position: absolute;
    height  : 50px;
    margin  : 1% 0 0 1%;
    cursor  : pointer;
    border-radius: 20px;
}

.icon {
    margin-top: 5px;
    font-size : 30px;
    text-align: center;
}