.card {
    width        : 100%;
    height       : auto;
    position     : relative;
    box-shadow   : 10px 10px 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    margin: 1% auto;
}

.btnContainer {
    width         : 80%;
    margin        : auto;
    padding-bottom: 10%;
    bottom        : 0;

}

.button {
    padding        : 2.5vh;
    border-radius  : 10px;
    font-size      : 20px;
    display        : flex;
    justify-content: center;
    text-align     : center;
    cursor         : pointer;
    transition     : all .3s ease-in-out;
    outline        : none;
    border         : none;
    background     : var(--secondary);
    opacity        : 0.6;
}

.button:hover {

    border-radius: 45px;
    transform    : scale(1.2);
    background   : var(--primary);
    outline      : none;
    border       : none;
    color        : var(--tertiary);
    opacity      : 0.9;
}

.button:active {
    transform: scale(0.7);
}


.subTitle {
    font-family: var(--title);
    color      : var(--secondary)
}


/* ITEM CLASS CSS */
.service {
    overflow : auto;
    font-size: medium;
    color    : var(--primary);
    padding  : 8%;
}

.back {
    position: absolute;
    left: 5%;
    cursor: pointer;
}

.forward {
    position: absolute;
    right   : 5%;
    cursor: pointer;
}

.icon {
    font-size: 25px;
}

