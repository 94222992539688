.roomContainer {
    height        : auto;
    width         : 90%;
    padding-bottom: 10vh;
    margin        : auto;
    background    : transparent
}


.emailContainer {
    position     : absolute;
    height       : auto;
    width        : 60%;
    left         : 10%;
    border-radius: 10px;
    overflow     : hidden;
}


.title {
    text-align : center;
    font-size  : 100px;
    font-family: var(--title);
    color      : var(--primary)
}

.subTitle {
    font-size  : 70px;
    font-family: var(--title);
    color      : var(--primary)
}

.text {
    text-align : justify;
    font-size  : 25px;
    font-family: var(--text);
    color      : var(--secondary)
}

.icon {
    font-size    : 32px;
    color        : var(--primary);
    margin-bottom: -10px;
    margin-right : 10px;
}

.link {
    text-align : justify;
    font-size  : 25px;
    font-family: var(--text);
    color      : var(--primary);
    cursor     : pointer;
    transition : all .3 ease-in-out;
}

.link:hover {
    color: var(--secondary);
}

@media screen and (max-width: 1200px) {

    .title {
        font-size: 90px;
    }

    .subTitle {
        font-size: 60px;
    }

    .link,
    .text {
        font-size: 22.5px;
    }
}


@media screen and (max-width: 960px) {

    .title {
        font-size: 80px;
    }

    .subTitle {
        font-size: 50px;
    }

    .link,
    .text {
        font-size: 20px;
    }
}


@media screen and (max-width: 450px) {

    .title {
        font-size: 70px;
    }

    .subTitle {
        font-size: 40px;
    }

    .link,
    .text {
        font-size: 16px;
    }
}
