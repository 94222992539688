.emailStyle {
    margin-top   : 50px;
    padding      : 0 0 10% 0%;
    border-radius: 20px;
    list-style   : none;
    width: 100%;
}

.inputWrapper {
    display              : grid;
    grid-template-columns: repeat(2, 50%);
}

.inputWrapperThree {
    display              : grid;
    grid-template-columns: repeat(3, 33%);
}

.input {
    width           : 80%;
    display         : flex;
    padding         : 6px 12px;
    font-size       : 20px;
    line-height     : 1.43;
    color           : #555;
    border          : none;
    border-bottom   : 2px solid rgba(0, 0, 0, 0.26);
    opacity         : .5;
    background-color: transparent;
    transition      : all 0.5s ease-in-out;
}

.input:focus {
    outline      : none;
    border-bottom: 2px solid orange;
    opacity      : .5;
}


.inputArea {
    width           : 90%;
    display         : block;
    height          : 200px;
    padding         : 6px 12px;
    font-size       : 14px;
    line-height     : 1.43;
    color           : #555;
    border          : none;
    border          : 2px solid rgba(0, 0, 0, 0.103);
    border-radius   : 4px;
    background-color: transparent;
    transition      : all 0.3s ease-in-out;
    resize          : none;
}

.inputArea:focus {
    outline: none;
    border : 2px solid orange;
    opacity: .5;
}

.errorArea {
    border : none;
    border : 2px solid red;
    opacity: .5;
}

.error {
    border-bottom: 2px solid red;
    opacity      : .5;
}
.correct{
    border-bottom: 2px solid green;
    opacity      : .5;
}

.correctArea {
    border : none;
    border : 2px solid green;
    opacity: .5;
}

.submitButton {
    padding        : 2.5vh;
    float          : right;
    margin-right   : 10%;
    margin-top     : 2%;
    border-radius  : 10px;
    font-size      : 20px;
    display        : flex;
    justify-content: center;
    text-align     : center;
    cursor         : pointer;
    transition     : all .3s ease-in-out;
    outline        : none;
    border         : none;
    background     : var(--secondary);
    opacity        : 0.6;
}

.submitButton:hover {

    border-radius: 45px;
    transform    : scale(1.2);
    background   : var(--primary);
    outline      : none;
    border       : none;
    color        : var(--tertiary);
    opacity      : 0.9;
}

.submitButton:active {
    transform: scale(0.7);
}

.icon {
    font-size  : 35px;
    padding-top: 20px;
    margin-left: -10px;
    transition : all .3s ease-in-out;
    color      : var(--tertiary)
}

.icon:hover {
    color    : var(--secondary);
    transform: scale(1.3);
}

@media screen and (max-width: 960px) {
    .inputWrapper {
        display              : grid;
        grid-template-columns: repeat(1, auto);
    }
    .input {
        width           : 90%;
    }
    .submitButton {
        padding        : 1.5vh;  
        font-size      : 16px;
    }
}