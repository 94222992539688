.container {
    height        : auto;
    width         : 90%;
    padding-bottom: 10vh;
    margin        : auto;
    background    : transparent;
}


.emailContainer {
    position     : absolute;
    height       : auto;
    width        : 60%;
    left         : 10%;
    border-radius: 10px;
    overflow     : hidden;
}


.title {
    text-align : center;
    font-family: var(--title);
    color      : var(--primary);
    padding: 2rem 0 2rem 0;
}

.subTitle {
    font-family: var(--title);
    color      : var(--primary);
    padding: 2rem 0 2rem 0;
}

.text {
    text-align : justify;
    font-family: var(--text);
    color      : var(--secondary);
}

.btnContainer {
    width : fit-content;
    height: auto;
    margin: 5% auto;
}

.button {
    border-radius: 10px;
    font-family  : var(--title);
    cursor       : pointer;
    transition   : all .3s ease-in-out;
    outline      : none;
    border       : none;
    background   : var(--secondary);
    color        : black;
    opacity      : 0.6;
}

.button:hover {
    border-radius: 85px;
    transform    : scale(1.2);
    background   : var(--primary);
    outline      : none;
    border       : none;
    color        : var(--tertiary);
    opacity      : 0.9;
}

.button:active {
    transform: scale(0.7);
}


