.homeContainer {
    width     : 100%;
    height    : auto;
    background: rgba(0, 0, 0, 0.534);
}

.homeWrapper {
    background   : #fff;
    margin-bottom: 0;
    padding      : 5%;
}

.title {
    font-size: 35px;
    padding  : 100px;

}

.title {
    text-align : center;
    font-size  : 100px;
    font-family: var(--title);
    color      : var(--primary)
}

.subTitle {
    font-size  : 70px;
    font-family: var(--title);
    color      : var(--primary)
}

.text {
    text-align    : justify;
    /* font-size  : 25px; */
    font-family   : var(--text);
    color         : var(--secondary)
}

.image {
    border-radius: 45px;

}


.btnContainer {
    width : fit-content;
    height: auto;
    margin: 10% auto;
}

.buttonReview,
.button {
    border-radius: 10px;
    font-family  : var(--title);
    cursor       : pointer;
    transition   : all .3s ease-in-out;
    outline      : none;
    border       : none;
    background   : var(--secondary);
    color        : black;
    opacity      : 0.6;
}
.buttonReview {
    background   : var(--primary);
    
}

.buttonReview:hover,
.button:hover {
    border-radius: 85px;
    transform    : scale(1.2);
    background   : var(--primary);
    outline      : none;
    border       : none;
    color        : var(--tertiary);
    opacity      : 0.9;
}

.buttonReview:hover{
    background   : var(--secondary);
}

.button:active {
    transform: scale(0.7);
}





@media screen and (max-width: 450px) {
    .homeContainer {
        width     : 93%;
        height    : auto;
        background: rgba(0, 0, 0, 0.534);
    }
}