.contactContainer {
    height        : auto;
    width         : 90%;
    padding-bottom: 10vh;
    margin        : auto;
    background    : transparent
}


.emailContainer {
    position     : absolute;
    height       : auto;
    width        : 60%;
    left         : 10%;
    border-radius: 10px;
    overflow     : hidden;
}

.background {
    position  : absolute;
    height    : 100%;
    width     : 100%;
    background: var(--secondary);
    z-index   : -1;
}

.toggle {
    outline      : none;
    border       : none;
    cursor       : pointer;
    position     : absolute;
    top          : 18px;
    left         : 15px;
    width        : 50px;
    height       : 50px;
    border-radius: 50%;
    background   : transparent;
    transition   : all .3s ease-in-out;
}

.toggle:hover {
    transform: scale(1.2);
}

.title {
    text-align : center;
    font-family: var(--title);
    color      : var(--primary);
    padding    : 2rem 0 2rem 0;
}

.subTitle {
    font-family: var(--title);
    color      : var(--primary);
    padding    : 2rem 0 2rem 0;
}

.text {
    text-align : justify;
    font-family: var(--text);
    color      : var(--secondary);
}

.icon {
    font-size    : 32px;
    color        : var(--primary);
    margin-bottom: -10px;
    margin-right : 10px;
}

.no_link {
    text-align : justify;
    font-family: var(--text);
    color      : var(--primary);
}

.link:hover {
    color: var(--secondary);
}

.link {
    text-align : justify;
    font-family: var(--text);
    color      : var(--primary);
    cursor     : pointer;
    transition : all .3 ease-in-out;
}

.link:hover {
    color: var(--secondary);
}

.logo {
    width  : 25%;
    padding: 1rem 0 1rem 0;
    border-radius: 15px 15px 15px 15px;
}



@media screen and (max-width: 550px) {
    .logo {
        width: 35%;
    }
}