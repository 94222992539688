.navbarContainer {
    background           : var(--tertiary);
    height               : 110px;
    border-bottom        : 2px var(--primary) solid;
    display              : grid;
    grid-template-columns: repeat(6, 17%);
    width                : 100%;
    transition           : all .3s ease-in;
    margin-block-start   : 0;
    margin-block-end     : 0;
    margin-inline-start  : 0px;
    margin-inline-end    : 0px;
    padding-inline-start : 0;
    text-align           : center;
    align-items          : center;
    list-style           : none;
    margin               : auto;

}

.scrolled {
    height  : 60px;
    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 100;
}

.navItem {
    justify-content: center;
    text-align     : center;
}

.logo {
    position     : relative;
    height       : 100px;
    padding      : 10px;
    cursor       : pointer;
    border-radius: 20px;
}



.navWrap {
    color         : var(--primary);
    list-style    : none;
    margin        : auto;
    /* font-size  : 25px; */
    cursor        : pointer;
    font-family   : 'Yellowtail', cursive;
    border        : none;
}


.navWrap:hover {
    color: var(--primary);

}

.navWrap::after {
    content   : '';
    display   : block;
    width     : 0;
    transition: width .4s ease-in-out;
}


.navWrap:hover::after {
    margin       : auto;
    width        : 70%;
    border-bottom: 2px solid var(--primary);
}

.navWrapActive {
    position     : relative;
    list-style   : none;
    margin       : auto;
    cursor       : default;
    color        : var(--secondary);
    border-left  : 3px solid;
    border-bottom: 3px solid;
    border-radius: 50% 50% 50% 50%;
}



@media screen and (max-width: 960px) {

    .navbarContainer {
        height       : 80px;
        border-bottom: 2px var(--primary) solid;
        display      : flex;
    }

    .navWrap {
        color      : var(--primary);
        list-style : none;
        margin     : 10px 0 0 30px;
        cursor     : pointer;
        font-family: 'Yellowtail', cursive;
    }

    .scrolled {
        height: 50px;
    }

    .logo {
        height : 70px;
        padding: 5px;
    }


    .navWrap:hover::after {
        width: 0%;
    }
}