.container {
    cursor     : pointer;
    align-items: center;
    height     : auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.figure {
    width              : 400px;
    text-align         : center;
    height             : 80px;
    margin             : auto;
    margin-block-start : 0rem;
    margin-inline-start: 0rem;
    margin-inline-end  : 0rem;
    margin-block-end   : 0rem;
    margin-top        : 10px;


}

.img {
    width : 250px;
    height: 60px;
}

@media screen and (max-width: 960px) {
    .container {
        height     : 150px;
    
    }
    .figure {
        width              : 500px;
        height             : 75px;
        margin             : auto;
        margin-top        : 35px;
    }
    .img {
        width : 300px;
        height: 75px;
    }
}

@media screen and (max-width: 500px) {
    .container {
        height     : 100px;
    
    }
    .figure {
        width              : 400px;
        height             : 50px;
        margin             : auto;
        margin-top        : 25px;
    }
    .img {
        width : 200px;
        height: 50px;
    }
}