.container {
    height    : auto;
    width     : 100%;
    background: rgba(0, 0, 0, 0.925);
    position  : fixed;
    bottom    : 0;
    border-top: 2px solid var(--primary);
    z-index   : 100;

}

.wrapper {
    padding        : 1rem;
    width          : 80%;
    margin         : auto;
    color          : white;
    justify-content: center;
    text-align     : center;
}


.backdrop {
    position  : fixed;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modal {
    max-width    : 80%;
    max-height   : 80%;
    margin       : auto;
    padding      : 20px;
    background   : white;
    border-radius: 10px;
    text-align   : center;
    font-family  : var(--text);
    overflow     : auto;

}

.normativaContainer {
    margin: 20px;
}

.btnInfo,
.btn {
    margin       : 1rem;
    color        : var(--tertiary);
    background   : var(--primary);
    outline      : none;
    border       : none;
    border-radius: 5px;
    font-size    : var(--font-text);
    padding      : .5rem 2rem .5rem 2rem;
    cursor       : pointer;
    transition   : all .3s ease-in-out
}

.btnInfo {
    background: transparent;
    border    : 1px solid var(--tertiary);
}

.btnInfo:hover,
.btn:hover {
    background   : var(--tertiary);
    color        : var(--primary);
    transform    : scale(1.1);
    border-radius: 20px;
}

.btnClose {
    float     : right;
    transform : scale(1);
    transition: all .2s ease-in-out;
    cursor    : pointer;
}

.btnClose:hover {
    transition: all .2s ease-in-out;
    transform : scale(2);
}