.dropbtn {

    color           : white;
    border          : none;
    font-size       : 40px;
    padding         : 20px;
    cursor          : pointer;
}

.dropdown {
    position: relative;
    display : inline-block;
    height  : auto;
}

.content {
    position            : absolute;
    z-index             : 1;
    font-size           : 40px;
    text-align          : center;
    margin              : auto;
    justify-content     : center;
    margin-block-start  : 0;
    margin-block-end    : 0;
    margin-inline-start : 0px;
    margin-inline-end   : 0px;
    padding-inline-start: 0;
}

.item {
    display: block;
    padding: 20px;
}

.item:hover {
    background-color: #ddd;
    cursor          : pointer;
    border-radius   : 10px;
}

.dropdown:hover .content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: transparent;
}