:root {
    --slideBackground: trasparent
}

.sliderContainer {
    width         : 100%;
    height        : auto;
    padding-bottom: 20px;
    text-align    : center;
   
}

.image {
    width: 100%;
}

.textContainer {
    height          : 250px;
    width           : 450px;
    border-radius   : 10px 10px 10px 10px;
    position        : absolute;
    left            : 5%;
    bottom          : -100px;
    background-color: var(--primary);
    color           : #FFFFFF;
    font-weight     : bold;
    -webkit-filter  : drop-shadow(0 6px 20px rgba(221, 18, 18, 0.212));
    filter          : drop-shadow(0 6px 10px rgba(2, 2, 2, 0.493));

}

.right {
    left: 70%;
}

.title {
    color     : var(--ternary);
    margin-top: 15px;
    font-family: 'Libre Baskerville', serif;
    font-style:  italic;
    font-weight: 900;
    text-transform: uppercase;
}

.text {
    margin-top    : 20px;
    height        : 75%;
    width         : auto;
    background    : #fff;
    color         : var(--secondary);
    border-radius : 0px 0px 10px 10px;
    text-align    : justify;
    padding-right : 25px;
    padding-left  : 25px;
    padding-top   : 10px;
    padding-bottom: 10px;
    font-family: 'Merriweather', serif;

}



/* ARROW CLASS CSS  */

@keyframes left-right {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}

@keyframes right-left {
    from {
        -webkit-transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(-10px);
    }

    to {
        -webkit-transform: translateX(0px);
    }
}



.arrow {
    position     : absolute;
    outline      : none;
    transition   : all 0.5s;
    border-radius: 35px;
    z-index      : 1;
    border       : none;
    background   : rgba(0, 0, 0, 0.281);
    min-width    : 43px;
    min-height   : 43px;
    opacity      : 1;
    cursor       : pointer;
}

.arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}

.arrow::before {
    font-size  : 20px;
    color      : #fff;
    display    : block;
    font-family: revicons;
    text-align : center;
    z-index    : 2;
    position   : relative;
}

.arrowLeft {
    left: calc(4% + 1px);
}

.arrowLeft:hover {
    transform                        : scale(1.2);
    animation                        : right-left 1s ease-in-out;
    -webkit-animation                : right-left 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
    background: var(--primary);
}

.arrowLeft::before {
    content: "\e824";
}

.arrowRight {
    right: calc(4% + 1px);
}

.arrowRight:hover {
    transform                        : scale(1.2);
    animation                        : left-right 1s ease-in-out;
    -webkit-animation                : left-right 1s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
    background: var(--primary);
}

.arrowRight::before {
    content: "\e825";
}

/* DOT CLASS CSS */

.dotList {
    position       : absolute;
    bottom         : 50px;
    display        : flex;
    left           : 0;
    right          : 0;
    justify-content: center;
    margin         : auto;
    padding        : 10px;
    margin         : 0;
    list-style     : none;
    text-align     : center;
}

.dot {
    display      : inline-block;
    width        : 12px;
    height       : 12px;
    border-radius: 50%;
    opacity      : 1;
    padding      : 5px 5px 5px 5px;
    box-shadow   : none;
    transition   : all .5s;
    border-width : 2px;
    padding      : 0;
    margin       : 0;
    margin-right : 10px;
    outline      : 0;
    cursor       : pointer;
    border       : none;
    background   : var(--secondary);
}

.dot:hover {
    transition                       : all .5s;
    background                       : var(--secondary);
    animation                        : open-close .8s ease-in-out;
    -webkit-animation                : open-close .8s ease-in-out;
    animation-iteration-count        : infinite;
    -webkit-animation-iteration-count: infinite;
}

.dotActive {
    display      : inline-block;
    width        : 15px;
    height       : 15px;
    border-radius: 20%;
    opacity      : 1;
    padding      : 5px 5px 5px 5px;
    box-shadow   : none;
    transition   : all .5s;
    border-width : 2px;
    padding      : 0;
    margin       : 0;
    margin-right : 6px;
    margin-bottom: 2px;
    outline      : 0;
    cursor       : pointer;
    background   : var(--primary);
    border       : none;
}

@keyframes open-close {
    from {
        border-radius: 50%;

    }

    50% {
        border-radius: 20%;
        background   : var(--primary);
    }

    to {
        border-radius: 50%;

    }
}

@media screen and (max-width: 1550px) {
    .right {
        left: 65%;
    }
}

@media screen and (max-width: 1350px) {
    .right {
        left: 60%;
    }
}

@media screen and (max-width: 1150px) {
    .right {
        left: 55%;
    }
    .textContainer {
        height          : 200px;
        width           : 350px;
    }
}

@media screen and (max-width: 800px) {
    .right {
        left: 45%;
    }
    .dotList{
        bottom: 20px;
    }
}

@media screen and (max-width: 650px) {
    .right {
        left: 5%;
    }
    .textContainer {
        height          : 150px;
        width           : 250px;
    }
    
}

@media screen and (max-width: 450px) {
    .answer {
        font-size: 14px;
    }
}