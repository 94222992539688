@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
@import '@creativebulma/bulma-tooltip';

:root {
  --primary       : #9fb79f;
  /* --primary    : #92AD40; */
  /* --primary    : #8B0000; */
  --secondary     : #535353d8;
  --tertiary      : #ffffffd3;
  --alternative   : #92AD40;
  --title         : 'Yellowtail', cursive;
  --text          : 'Merriweather', serif;
}


.whatsAppClass {
  position : fixed;
  bottom   : 30px;
  right    : 30px;
  font-size: 24px;
  z-index  : 10;
}

.whatsAppIcon {
  color           : green;
  background-color: white;
  border-radius   : 90px 90px 90px 10px;
  font-size       : 40px;
  cursor          : pointer;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background   : var(--primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}


body {
  width: 100%;
}