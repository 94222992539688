.footerContainer {
    color           : #fff;
    justify-content : center;
    align-items     : center;
    left            : 0;
    bottom          : 0;
    z-index         : -1;
    width           : 100%;
    top             : 100%;
    left            : 0;
    transform       : translateY(-100%);
    background-color: #363839;
    font-size       : 25px;
    font-family     : var(--text);
    border-top      : 3px solid var(--secondary);
}

.footerLine {
    color           : #fff;
    border-top      : 1px solid var(--primary);
    background-color: #282a2b;
    font-family     : var(--text);
}

.firma {
    padding   : 2rem;
    text-align: center;

}

.firmaLink {
    font-weight: 900;
    color      : var(--secondary);
    cursor     : pointer;
    transition : all 0.3s ease-in-out;
}

.firmaLink:hover {
    color: var(--primary);
}

.arrowIcon {
    cursor    : pointer;
    transition: color .5s ease-in-out;
}

.arrowIcon:hover {
    color: var(--primary);
}

.logo {
    cursor    : pointer;
    transition: all .3s ease-in-out;
    color     : #fff;
    height    : 150px;
    border-radius: 10px;
}

.logo:hover {
    transform: scale(1.1);
}

.link {
    cursor      : pointer;
    transition  : all .3s ease-in-out;
    color       : #fff;
    padding-left: 10px;
}

.link:hover {
    color: var(--primary);
}

.subTitle {
    text-transform: uppercase;
    font-family: var(--title);
    color      : var(--primary);
    padding    : 1rem 0 0 0;
    font-weight: 800;
}

.text {
    text-align : justify;
    font-family: var(--text);
    color      : var(--secondary);
}

.item {
    margin-top: 1rem;
    color     : #fff;
}
.icon{
    height: 50px;
    transform: scale(1);
    transition: all .2s ease-in-out;
}
.icon:hover{
    transform: scale(1.3);
}

.maps {
    border   : 'none';
    width    : 80%;
    height: 50vh;
}

@media screen and (max-width: 960px) {
    .maps{
        width    : 100%;
        height: 50vh;
    }
    .logo {
        height    : 100px;
    }
}