.formControl {
    margin: 20px 0 20px 0;
}

.error {
    margin-top: 10px;
    color     : red;font-size: 20px;
}

.error::before {
    content: '✗ ';
}

.label {
    font-size  : 24px;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
    color      : var(--primary);
}