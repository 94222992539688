.roomsContainer {
    width         : 95%;
    height        : auto;
    padding-bottom: 10vh;
    margin        : auto;
}


.roomTitle {
    text-align : center;
    padding    : 20px;
    font-size  : 6vw;
    font-family: 'Yellowtail', cursive;
    color      : var(--primary)
}



@media screen and (max-width: 960px) {
    .roomTitle {
        font-size: 8vw;
    }
}