:root {
    --success    : #059900;
    --wrong  : #d60000d8;
  }


.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
  }
  
  .modal{
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    padding: 40px 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
    font-family: var(--text);
  }